.inner-styled-list {
  display: flex;
  flex-wrap: wrap;
  //gap: size(5px);
  width: 100%;
  //margin: size(20px) 0 size(20px) auto;
  padding: 0 0 0 size(20px);
  list-style: none;

  @include vp-1023 {
    margin: size(20px) 0;
    padding: 0;
  }

  @include vp-767 {
    margin: size(10px) 0;
  }

  &--margin-big {
    @include vp-767 {
      margin: size(20px) 0;
    }
  }

  &__item p {
    margin: 0;
    padding: size(8px) size(15px) size(12px);
    font-size: size(15px);
    line-height: size(20px);
    background-color: rgba($color-default-white, 0.6);
    border-radius: size(20px);
  }

  &--bg-snow p {
    background-color: $color-snow;
  }

  &__subtitle {
    width: 100%;
    margin: size(65px) 0 0 auto;
    padding: size(5px) 0;
    font-size: size(20px);
    line-height: size(25px);

    @include vp-1023 {
      margin: size(40px) 0 0;
      padding: 0;
    }

    &:not(:last-child) {
      margin-bottom: size(25px);

      @include vp-1023 {
        margin-bottom: size(20px);
      }

      @include vp-767 {
        margin-bottom: size(5px);
      }
    }

    &--font-40-35-20 {
      font-size: size(40px);
      line-height: size(45px);

      @include vp-1023 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }

    &--font-30-28-20 {
      font-size: size(28px);
      line-height: size(30px);

      @include vp-1023 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }
  }

  //--PixelPerfect--↓
  &--padding-middle {
    padding: 0 size(90px) 0 size(20px);

    @include vp-1023 {
      padding: 0;
    }
  }

  &--padding-big {
    padding: 0 size(240px) 0 size(20px);

    @include vp-1023 {
      padding: 0 size(240px) 0 0;
    }

    @include vp-767 {
      padding: 0;
    }
  }

  &--padding-20-20 {
    padding: 0 size(20px) 0 size(20px);

    @include vp-1023 {
      padding: 0;
    }

    @include vp-767 {
      padding: 0;
    }
  }

  //--PixelPerfect--↑

  //--Browser-bug-fix--iOS 13 (gap)--↓
  margin: size(20px) 0 size(15px) auto;

  @include vp-1023 {
    margin: size(20px) 0 size(15px);
  }

  @include vp-767 {
    margin: size(10px) 0 size(5px);
  }

  &--margin-big {
    @include vp-767 {
      margin: size(20px) 0 size(15px);
    }
  }

  &__item {
    padding: size(2.5px);
    margin-left: size(-2.5px);
    margin-right: size(2.5px);
    margin-top: size(-2.5px);
    margin-bottom: size(2.5px);
  }

  //--Browser-bug-fix--iOS 13--↑

  &--aside {
    margin: 0;
    padding: 0;

    @include vp-1023 {
      margin: 0;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &--margin-top-small {
    margin-top: size(20px);

    @include vp-1023 {
      margin-top: size(20px);
    }

    @include vp-767 {
      margin-top: size(10px);
    }

    &.inner-styled-list--margin-top-fix {
      margin-top: size(20px);
    }

    &.pp-fix {
      padding-top: size(5px);
      margin-bottom: size(-5px);

      @include vp-767 {
        margin-bottom: size(-10px);
      }
    }
  }

  &--margin-top-middle {
    margin-top: size(40px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(20px);
    }

    &.inner-styled-list--margin-top-fix {
      margin-top: size(40px);
    }

    &.pp-fix {
      padding-top: size(5px);
      margin-bottom: size(-5px);

      @include vp-767 {
        margin-bottom: size(-10px);
      }
    }
  }

  &--margin-top-big {
    margin-top: size(65px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(40px);
    }

    &.inner-styled-list--margin-top-fix {
      margin-top: size(65px);
    }

    &.pp-fix {
      padding-top: size(5px);
      margin-bottom: size(-5px);

      @include vp-767 {
        margin-bottom: size(-10px);
      }
    }
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }
}
